import React, {useEffect} from 'react'
import styled from 'styled-components'
import { useTranslation } from "react-i18next";
import { colors } from '../../themes/colors';

const ChildSafety = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t(`privacyPolicy.collectionOfInformation.childSafety`)
    }, []);

    return (
        <Container>
            <Subtitle2>{t(`childSafety.paragraph1`)}</Subtitle2>
            <Text>{t(`childSafety.zeroTolerancePolicy`)}:</Text>
            <ul>
                <li>{t(`childSafety.objectives.preventCSAM`)}</li>
                <li>{t(`childSafety.objectives.protectMinors`)}</li>
                <li>{t(`childSafety.objectives.collaborateLawEnforcement`)}</li>
                <li>{t(`childSafety.objectives.reviewSecurityMeasures`)}</li>
                <li>{t(`childSafety.objectives.trainEmployees`)}</li>
            </ul>
          <Subtitle2>{t(`childSafety.paragraph2`)}</Subtitle2>
          <Text>{t(`childSafety.ageVerificationPolicy`)}:</Text>
          <ul>
            <li>{t(`childSafety.ageVerificationMeasures.minimumAge`)}</li>
            <li>{t(`childSafety.ageVerificationMeasures.multiStepVerification`)}</li>
            <li>{t(`childSafety.ageVerificationMeasures.regularProfileChecks`)}</li>
            <li>{t(`childSafety.ageVerificationMeasures.immediateAccountBlock`)}</li>
          </ul>

          <Subtitle2>{t(`childSafety.paragraph3`)}</Subtitle2>
          <Text>{t(`childSafety.childSafetyReportingIntroduction`)}:</Text>
          <ul>
            <li>{t(`childSafety.childSafetyReportingChannels.inAppComplaintButton`)}</li>
            <li>{t(`childSafety.childSafetyReportingChannels.dedicatedEmail`)}: <Link>app.gogo.io@gmail.com</Link></li>
            <li>{t(`childSafety.childSafetyReportingChannels.24_7Monitoring`)}</li>
            <li>{t(`childSafety.childSafetyReportingChannels.immediateReporting`)}</li>
            <li>{t(`childSafety.childSafetyReportingChannels.anonymousReporting`)}</li>
          </ul>
          <Subtitle2>{t(`childSafety.paragraph4`)}</Subtitle2>
          <Text>{t(`childSafety.contentModerationStatement`)}:</Text>
          <ul>
            <li>{t(`childSafety.contentModerationSystems.aiImageVideoScanning`)}</li>
            <li>{t(`childSafety.contentModerationSystems.constantProfileContentModeration`)}</li>
            <li>{t(`childSafety.contentModerationSystems.immediateContentRemoval`)}</li>
          </ul>

          <Subtitle2>{t(`childSafety.paragraph5`)}</Subtitle2>
          <Text>{t(`childSafety.complianceStatement`)}:</Text>
          <ul>
            <li>{t(`childSafety.complianceWithLaws.ukOnlineSafetyAct2023`)}</li>
            <li>{t(`childSafety.complianceWithLaws.ukChildOnlineSafetyRules`)}</li>
            <li>{t(`childSafety.complianceWithLaws.gdprMinorDataProcessingRequirements`)}</li>
            <li>{t(`childSafety.complianceWithLaws.mandatoryCSAMReporting`)}</li>
            <li>{t(`childSafety.complianceWithLaws.regularComplianceChecks`)}</li>
          </ul>
          <Subtitle2>{t(`childSafety.paragraph6`)}</Subtitle2>
          <Text>{t(`childSafety.childSafetyConcernsIntroduction`)}:</Text>
          <ul>
            <li>{t(`childSafety.childSafetyConcerns.childSafetyOfficer`)}: <Link>app.gogo.io@gmail.com</Link></li>
            <li>{t(`childSafety.childSafetyConcerns.reportCSAM`)}</li>
            <li>{t(`childSafety.childSafetyConcerns.generalInquiries`)}: <Link>app.gogo.io@gmail.com</Link></li>
          </ul>
        </Container>
    )
}

export default ChildSafety;


const Container = styled.div`
    margin: 0 auto;
        width: 70%;
        padding: 24px 24px;
        background: white;
        text-wrap: wrap;
        ul li {
                color: #505965;
                margin-top: 12px;
                margin-bottom: 12px;
                font-size: 18px;
        }

  li::marker {
    content: counter(list-item) '. ';
    color: #505965;
    font-weight: bold;
  }
`

const Title = styled.h1`
  color: #21262e;
`
const Subtitle2 = styled.h2`
  color: #21262e;
`
const Text = styled.p`
  color: #505965;
  font-size: 18px;
`
const Link = styled.a`
  color: ${colors.blue6};
  font-weight: 500;
`
