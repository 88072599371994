import React from 'react'
import Privacy from './pages/about/privacy.policy'
import Terms from './pages/about/terms.of.use'
import CommunityGuidelines from './pages/about/community.guidelines'
import Safety from './pages/about/safety'
import ChildSafety from './pages/about/child-safety'
import SupportDeleteAccount from './pages/about/support.delete.account'
import { Routes, Route } from "react-router-dom";

import './features/i18next';


const routes = {
    privacyPolicy: `/privacy-policy`,
    termsOfUse: `/terms-of-use`,
    communityGuidelines: `/community-guidelines`,
    safety: `/safety`,
    childSafety: `/safety-child`,
    deleteAccount: `/support/delete-account`,
}
const App = () => {
  return (
          <Routes>
              <Route path={routes.privacyPolicy} element={<Privacy/>} />
              <Route path={routes.termsOfUse} element={<Terms/>} />
              <Route path={routes.communityGuidelines} element={<CommunityGuidelines/>} />
              <Route path={routes.safety} element={<Safety/>} />
              <Route path={routes.deleteAccount} element={<SupportDeleteAccount/>} />
              <Route path={routes.childSafety} element={<ChildSafety/>} />
          </Routes>
  )
}
export default App
